import React, { useState } from 'react';
import './faqs.css';

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Why did you start this business?",
      answer: "The owner of the business is a big fan of the game and wanted to create a place to play the game in Seattle area. Our mission is to bring fun and joy to everyone in the community. We believe claw machines should be an enjoyable experience for all - a place where people can relieve stress, where children can happily win prizes, and where everyone can create cherished memories. This is truly a game for everyone to enjoy!"
    },
    {
      question: "Can I host my birthday party here?",
      answer: "Of course! We do host birthday parties and other special events here. Please contact us for more information."
    },
    {
      question: "When are you open?",
      answer: "We highly recommend you checking the business hours on our Google Maps page before coming to the store. We usually have special hours during holiday seasons."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faqs" className="faqs-section">
      <div className="faqs-container">
        <div className="faqs-header">
          <h2 className="faqs-title">Frequently Asked Questions</h2>
          <p className="faqs-subtitle">Find answers to common questions about Lucky Claww</p>
        </div>
        <div className="faqs-list">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className={`faq-item ${openIndex === index ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <div className="faq-icon">
                  <svg 
                    viewBox="0 0 24 24" 
                    className={`icon ${openIndex === index ? 'rotate' : ''}`}
                  >
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </div>
              </div>
              <div className={`faq-answer ${openIndex === index ? 'show' : ''}`}>
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="faqs-contact">
          <p>Still have questions?</p>
          <a href="mailto:luckyclaww@gmail.com" className="contact-button">
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
