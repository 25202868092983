import React from 'react'
import { Helmet } from 'react-helmet'

import Reviews from '../components/reviews'
import FAQs from '../components/faqs'
import Footer from '../components/footer'
import Navbar from '../components/home/Navbar'
import Hero from '../components/home/Hero'

import './home.css'

const Home = () => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Lucky Claww - Seattle Claw Machine Arcade</title>
        <meta property="og:title" content="Lucky Claww - Seattle Claw Machine Arcade" />
        <link rel="icon" type="image/png" href="/lc-logo.png" />
      </Helmet>
      <Hero>
        <header className="home-header10">
          <Navbar />
        </header>
      </Hero>
      <div id="how-to-play" className="home-section">
        <div className="home-header-container">
          <div className="home-header">
            <h2 className="home-heading">
              <i className="fas fa-gamepad heading-icon-left"></i>
              <span>How to Play</span>
              <i className="fas fa-gift heading-icon-right"></i>
              <i className="fas fa-crown heading-icon-right"></i>
            </h2>
          </div>
        </div>
        <div className="home-steps">
          <div className="home-step">
            <div className="home-step-number">1</div>
            <div className="home-step-content">
              <img
                alt="Purchase Tokens"
                src="/steps/step1.jpeg"
                className="home-step-image"
              />
              <h3>Purchase Tokens</h3>
              <p>To start, you will need to buy tokens to play. Each machine costs around 2 to 5 tokens per round so keep that in mind when purchasing tokens!</p>
            </div>
          </div>
          <div className="home-step">
            <div className="home-step-number">2</div>
            <div className="home-step-content">
              <img
                alt="Play and Have Fun"
                src="/steps/step2.jpg"
                className="home-step-image"
              />
              <h3>PLAY AND HAVE FUN</h3>
              <p>EVERYONE IS A WINNER! Each plushie in the machines is worth a certain amount of points. This means you can win whatever is easy for you instead of spending too many coins on one plush! Based on the number of coins you purchase, we'll guarantee you to win a minimum number of plushie points, so everyone walks away with prizes. See the table below for details on guaranteed points per purchase.</p>
            </div>
          </div>
          <div className="home-step">
            <div className="home-step-number">3</div>
            <div className="home-step-content">
              <img
                alt="Upgrade or Trade"
                src="/steps/step3.png"
                className="home-step-image"
              />
              <h3>UPGRADE/TRADE</h3>
              <p>OPTIONAL: You could always trade-in unwanted plushies that you win to upgrade to another prize at the front counter. Upgrading gives you more selections for cuter and nicer plushies, but this is all up to you and your preference!</p>
            </div>
          </div>
        </div>
      </div>
      <section id="token-packages" className="home-comparision">
        <div className="home-header-container2">
          <div className="home-header12">
            <h2 className="home-heading11">
              <i className="fas fa-coins heading-icon-left"></i>
              <span>Token Packages</span>
              <i className="fas fa-star heading-icon-right"></i>
              <i className="fas fa-star heading-icon-right"></i>
              <i className="fas fa-star heading-icon-right"></i>
            </h2>
          </div>
        </div>
        <div className="home-table">
          <div className="home-row10">
            <div className="home-plans1">
              <div className="home-row11">
                <span className="home-text22">Price</span>
                <span className="home-text23">Token</span>
                <span className="home-text24">Guaranteed plushie Points <em className="new-tag">Special!</em></span>
              </div>
            </div>
          </div>
          <div className="home-row12">
            <div className="home-plans2">
              <div className="home-row13">
                <div className="value">
                  <span className="home-text25">$20</span>
                </div>
                <div className="value">
                  <span className="home-text26">22</span>
                </div>
                <div className="value">
                  <span className="home-text27">6</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row14">
            <div className="home-plans3">
              <div className="home-row15">
                <div className="value">
                  <span className="home-text28">$56</span>
                </div>
                <div className="value">
                  <span className="home-text29">100</span>
                </div>
                <div className="value">
                  <span className="home-text30">20</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row16">
            <div className="home-plans4">
              <div className="home-row17">
                <div className="value">
                  <span className="home-text31">$80</span>
                </div>
                <div className="value">
                  <span className="home-text32">160</span>
                </div>
                <div className="value">
                  <span className="home-text33">32</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row18">
            <div className="home-plans5">
              <div className="home-row19">
                <div className="value">
                  <span className="home-text34">$135</span>
                </div>
                <div className="value">
                  <span className="home-text35">280</span>
                </div>
                <div className="value">
                  <span className="home-text36">56</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="locations" className="home-section">
        <div className="home-header-container">
          <div className="home-header">
            <h2 className="home-heading">Location & Hours</h2>
          </div>
        </div>
        <div className="home-steps" style={{ gap: "var(--dl-space-space-fourunits)" }}>
          <div className="home-step" style={{ flex: "2" }}>
            <div className="home-step-number">📍</div>
            <div className="home-step-content">
              <h3>Location</h3>
              <p style={{ marginBottom: "var(--dl-space-space-unit)" }}>
                14028 Bel-Red Rd, Unit 207<br />
                Bellevue, WA 98007<br />
                <a 
                  href="mailto:luckyclaww@gmail.com"
                  style={{ 
                    color: '#6caeec', 
                    textDecoration: 'none',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginTop: '8px'
                  }}
                >
                  <i className="fas fa-envelope"></i>
                  luckyclaww@gmail.com
                </a>
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.4910711292474!2d-122.16437812356238!3d47.62466087127754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906c7f638ef76f%3A0x6c2a0e4f5b8c8b0a!2s14028%20Bel-Red%20Rd%2C%20Bellevue%2C%20WA%2098007!5e0!3m2!1sen!2sus!4v1701927000000!5m2!1sen!2sus"
                className="home-step-image"
                style={{ height: "300px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="home-step">
            <div className="home-step-number">🕒</div>
            <div className="home-step-content">
              <h3>Business Hours</h3>
              <p>Please check Google Maps for the up-to-date business hours.</p>
            </div>
          </div>
        </div>
      </section>
      <Reviews />
      <FAQs />
      <Footer />
    </div>
  )
}

export default Home
