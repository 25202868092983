import React from 'react';
import '../../styles/components/navbar.css';

const Navbar = () => {
  return (
    <header data-thq="thq-navbar" className="home-navbar">
      <img alt="image" src="/lc-logo.png" className="home-branding1" />
      <div className="nav-container">
        <nav className="home-nav">
          <a href="#how-to-play" className="nav-link">How to Play</a>
          <a href="#token-packages" className="nav-link">Token Packages</a>
          <a href="#locations" className="nav-link">Locations</a>
          <a href="#reviews" className="nav-link">Customer Reviews</a>
          <a href="#faqs" className="nav-link">FAQs</a>
          <button onClick={() => window.location.href='/auth'} className="nav-button">Login/Register</button>
        </nav>
        <button onClick={() => window.location.href='/auth'} className="nav-button mobile-only">Login/Register</button>
      </div>
    </header>
  );
};

export default Navbar;
