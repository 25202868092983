import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxN7nKZeDNpxd7AgjOGqjH0CLmDEmMx3w",
  authDomain: "lucky-claww-membership.firebaseapp.com",
  projectId: "lucky-claww-membership",
  storageBucket: "lucky-claww-membership.firebasestorage.app",
  messagingSenderId: "844941992838",
  appId: "1:844941992838:web:cdcab510ab911bcf160207"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };
