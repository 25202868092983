import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase/config';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import './Dashboard.css';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copyStatus, setCopyStatus] = useState('');
  const [activityLogs, setActivityLogs] = useState([]);
  const [selectedSection, setSelectedSection] = useState('overview');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
            // Fetch activity logs
            const logsQuery = query(
              collection(db, 'activity_logs'),
              where('userID', '==', user.uid),
              orderBy('timestamp', 'desc')
            );
            const logsSnapshot = await getDocs(logsQuery);
            const logs = logsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              timestamp: doc.data().timestamp?.toDate()
            }));
            setActivityLogs(logs);
          } else {
            console.error("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      } else {
        navigate('/auth');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/auth');
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  const handleCopyCode = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus(''), 2000);
    } catch (err) {
      console.error('Failed to copy code:', err);
      setCopyStatus('Failed to copy');
    }
  };

  const renderOverviewSection = () => (
    <div className="overview-section">
      <div className="user-stats">
        <div className="stat-card">
          <h3>Points</h3>
          <p className="stat-value">{userData.points}</p>
        </div>
        {userData.coins > 0 && (
          <div className="stat-card">
            <h3>Tokens</h3>
            <p className="stat-value">{userData.coins}</p>
          </div>
        )}
      </div>

      {auth.currentUser && (
        <div className="referral-qr-section">
          <h3>Your QR Code</h3>
          <div className="qr-code-container">
            <QRCodeSVG 
              value={auth.currentUser.uid}
              size={200}
              level="H"
              includeMargin={true}
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderActivityLogsSection = () => (
    <div className="activity-logs">
      {activityLogs
        .filter(log => 
          log.previousValues?.points !== log.newValues?.points ||
          log.previousValues?.coins !== log.newValues?.coins ||
          log.previousValues?.stamps !== log.newValues?.stamps
        )
        .map((log) => (
          <div key={log.id} className="activity-log-item">
            <div className="log-header">
              <span className="log-timestamp">
                {log.timestamp?.toLocaleString()}
              </span>
              {log.type && log.type !== "admin_update" && (
                <span className="log-type">
                  {(() => {
                    switch(log.type) {
                      case 'referral_bonus_new_customer':
                        return '🎁 Welcome Bonus';
                      case 'referral_bonus_referrer':
                        return '🎉 Referral Reward';
                      case 'coin_redemption':
                        return '💰 Token Redemption';
                      case 'new_stamps_with_visit':
                        return '🧸 New Stamp';
                      case 'redem_stamps_for_prize':
                        return '🏆 Prize Claimed';
                      default:
                        return log.type;
                    }
                  })()}
                </span>
              )}
            </div>
            <div className="log-changes">
              {log.previousValues?.points !== log.newValues?.points && (
                <div className="points-change">
                  <span>Points: </span>
                  <span className="previous-value">{log.previousValues?.points}</span>
                  <span> → </span>
                  <span className="new-value">{log.newValues?.points}</span>
                </div>
              )}
              {log.previousValues?.coins !== log.newValues?.coins && (
                <div className="coins-change">
                  <span>Tokens: </span>
                  <span className="previous-value">{log.previousValues?.coins}</span>
                  <span> → </span>
                  <span className="new-value">{log.newValues?.coins}</span>
                </div>
              )}
              {log.previousValues?.stamps !== log.newValues?.stamps && (
                <div className="stamps-change">
                  <span>Stamps: </span>
                  <span className="previous-value">{log.previousValues?.stamps}</span>
                  <span> → </span>
                  <span className="new-value">{log.newValues?.stamps}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      {activityLogs.filter(log => 
        log.previousValues?.points !== log.newValues?.points ||
        log.previousValues?.coins !== log.newValues?.coins ||
        log.previousValues?.stamps !== log.newValues?.stamps
      ).length === 0 && (
        <div className="no-logs">No activity history available</div>
      )}
    </div>
  );

  const renderReferralSection = () => (
    <div className="referral-section">
      {userData.referralCode && (
        <div className="referral-code-section">
          <div className="referral-header">
            <h3>Share With Friends!</h3>
            <p className="referral-subtitle">Did you have fun in Lucky Claww? Want to bring joy to your friends? Share your referral link and both you and your friends will earn rewards! 🎁</p>
          </div>
          <div className="referral-url-container">
            <input
              type="text"
              value={`https://luckyclaww.com/auth?referral=${userData.referralCode}`}
              readOnly
              className="referral-url"
            />
            <button
              onClick={() => handleCopyCode(`https://luckyclaww.com/auth?referral=${userData.referralCode}`)}
              className="copy-button"
              title="Click to copy referral link"
            >
              {copyStatus || 'Copy'}
              {copyStatus && <span className="copy-status">{copyStatus}</span>}
            </button>
          </div>
        </div>
      )}
      {userData.referredBy && (
        <div className="referred-by-section">
          <p>Referred By:</p>
          <div className="referral-code">{userData.referredBy}</div>
          {userData.ReferralBonusClaimed && (
            <div className="bonus-claimed">
              <span className="bonus-icon">🎉</span>
              Referral bonus has been claimed!
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderStampCollectionSection = () => (
    <div className="stamp-collection-section">
      <h3>Stamp Card</h3>
      <div className="stamps-info">
        <p>You have collected {userData.stamps || 0} stamps!</p>
        {userData.stamps >= 8 ? (
          <p className="stamps-complete">Congratulations! You've completed your stamp collection! 🎉</p>
        ) : (
          <p className="stamps-remaining">Collect {8 - (userData.stamps || 0)} more stamps to get a special prize!</p>
        )}
      </div>
      <div className="stamps-container">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="stamp-wrapper">
            <img src="/stamp.png" alt="Empty stamp" className="stamp-image" />
            {index < (userData.stamps || 0) && (
              <img src="/lc-logo.png" alt="Collected stamp" className="stamp-overlay" />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="dashboard-container">
        <div className="error">Unable to load user data. Please try again.</div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">
        Welcome, {userData.name || userData.email}
        {userData.name && <span className="user-email">({userData.email})</span>}
      </h1>

      <div className="dashboard-content">
        <div className="section-navigation">
          <div 
            className={`nav-card ${selectedSection === 'overview' ? 'active' : ''}`}
            onClick={() => setSelectedSection('overview')}
          >
            <div className="nav-icon">📊</div>
            <div className="nav-info">
              <div className="nav-title">Overview</div>
              <div className="nav-details">
                <div className="detail-item">
                  <span className="detail-label">Points:</span>
                  <span className="detail-value">{userData.points}</span>
                </div>
                {userData.coins > 0 && (
                  <div className="detail-item">
                    <span className="detail-label">Tokens:</span>
                    <span className="detail-value">{userData.coins}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div 
            className={`nav-card ${selectedSection === 'activity' ? 'active' : ''}`}
            onClick={() => setSelectedSection('activity')}
          >
            <div className="nav-icon">📝</div>
            <div className="nav-info">
              <div className="nav-title">Activity History</div>
              <div className="nav-details">
                <div className="detail-item">
                  View your points and tokens history
                </div>
              </div>
            </div>
          </div>

          <div 
            className={`nav-card ${selectedSection === 'stamps' ? 'active' : ''}`}
            onClick={() => setSelectedSection('stamps')}
          >
            <div className="nav-icon">🧸</div>
            <div className="nav-info">
              <div className="nav-title">Stamp Card</div>
              <div className="nav-details">
                <div className="detail-item">
                  <span className="detail-label">Stamps:</span>
                  <span className="detail-value">{userData.stamps || 0}/8</span>
                </div>
              </div>
            </div>
          </div>

          <div 
            className={`nav-card ${selectedSection === 'referral' ? 'active' : ''}`}
            onClick={() => setSelectedSection('referral')}
          >
            <div className="nav-icon">🎁</div>
            <div className="nav-info">
              <div className="nav-title">Referral Program</div>
              <div className="nav-details">
                <div className="detail-item">
                  Share and earn rewards
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-content">
          {selectedSection === 'overview' && renderOverviewSection()}
          {selectedSection === 'activity' && renderActivityLogsSection()}
          {selectedSection === 'stamps' && renderStampCollectionSection()}
          {selectedSection === 'referral' && renderReferralSection()}
        </div>
      </div>

      <button className="logout-button" onClick={handleLogout}>Log Out</button>
    </div>
  );
};

export default Dashboard;
