import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="social-links">
          <div className="social-item">
            <div className="icon-wrapper">
              <a 
                href="https://instagram.com/luckyclaww" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <img 
                  src="/social_network_icons/Instagram.png" 
                  alt="Instagram"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
          <div className="social-item">
            <div className="icon-wrapper">
              <a 
                href="https://www.tiktok.com/@luckyclaww3" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <img 
                  src="/social_network_icons/tiktok.png" 
                  alt="TikTok"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
          <div className="social-item">
            <div className="icon-wrapper">
              <a 
                href="https://www.xiaohongshu.com/user/profile/61c7919b000000001000516d" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <img 
                  src="/social_network_icons/xiaohongshu.png" 
                  alt="Xiaohongshu"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <div className="copyright">
            2024 Lucky Claww. All rights reserved.
          </div>
          <div className="footer-info">
            <span>Seattle, WA</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
