import React from 'react';
import '../../styles/components/hero.css';

const Hero = ({ children }) => {
  return (
    <section className="home-hero">
      <video
        loop
        muted
        autoPlay
        playsInline
        className="home-video"
        poster="/hero-bg.png"
      >
        <source src="/play_claww.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {children}
      <div className="home-hero-content">
        <div className="home-header-container1">
          <h1 className="home-heading10">
            Adding fun to your life!
          </h1>
          <p className="home-caption10">
            The first dedicated claw arcade in Seattle
          </p>
        </div>
        <a 
          href="https://www.google.com/maps/search/Lucky+Claww+Seattle"
          target="_blank"
          rel="noopener noreferrer"
          className="home-button1"
        >
          Get started today
        </a>
      </div>
    </section>
  );
};

export default Hero;
