import React, { useState, useEffect, useRef } from 'react';
import './reviews.css';

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const intervalRef = useRef(null);
  const sliderRef = useRef(null);

  const reviews = [
    {
      name: "Daratina P",
      rating: 5,
      comment: "This is a suitable place for young people, dating, and family. Although the shop is a bit small, it is reasonable to relieve stress and fun. Price to buy token is okay, not too expensive, can trade.",
      date: "2 weeks ago"
    },
    {
      name: "Nicole B",
      rating: 5,
      comment: "My boyfriend took me here as a surprise for our 4 year anniversary, had no idea this hidden gem was here in Bellevue! We had a blast, the aesthetic is adorable and so well put together, and the owner is so incredibly sweet. The claw machines were really fun and not that hard either! The difficulty ratings were accurate and the games are so much more fun than other claw machines you'll play at a casino or bowling alley type place.",
      date: "1 week ago"
    },
    {
      name: "Cary C",
      rating: 5,
      comment: "One of the best claw machine places I've been to! Machines aren't rigged, prizes are good, workers are kind. Lots of fun! Will be back",
      date: "1 month ago"
    },
    {
      name: "Anna C",
      rating: 5,
      comment: "The attendants were very kind and helpful!! I am a claw fanatic so this spot was a gem for me. My boyfriend and I had a blast ",
      date: "2 months ago"
    },
    {
      name: "Cat C",
      rating: 5,
      comment: "This place is amazing. My 3 years old daughter is a big fun of claw machine. We spent so much on the machine which were so hard and she got really frustrated. Here she was able to get some on her own. The stuffed toys are super cute and feels good.",
      date: "3 months ago"
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const renderStars = (rating) => {
    return "".repeat(rating) + "".repeat(5 - rating);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    handleInteractionStart();
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (Math.abs(distance) >= minSwipeDistance) {
      if (distance > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    }

    setTouchStart(null);
    setTouchEnd(null);
    handleInteractionEnd();
  };

  const startAutoPlay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    intervalRef.current = setInterval(nextSlide, 5000);
  };

  const handleInteractionStart = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleInteractionEnd = () => {
    startAutoPlay();
  };

  useEffect(() => {
    startAutoPlay();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <section id="reviews" className="home-comparision">
      <div className="home-header-container">
        <div className="home-header">
          <h2 className="home-heading">What Our Customers Say</h2>
        </div>
      </div>
      <div 
        ref={sliderRef}
        className="reviews-slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={handleInteractionStart}
        onMouseLeave={handleInteractionEnd}
      >
        <button 
          className="slider-button prev" 
          onClick={() => {
            prevSlide();
            handleInteractionStart();
          }}
          aria-label="Previous review"
        >
          ❮
        </button>
        <div className="review-card">
          <div className="review-content">
            <div className="review-stars">{renderStars(reviews[currentIndex].rating)}</div>
            <p className="review-text">{reviews[currentIndex].comment}</p>
            <div className="review-author">
              <span className="author-name">{reviews[currentIndex].name}</span>
              <span className="review-date">{reviews[currentIndex].date}</span>
            </div>
          </div>
        </div>
        <button 
          className="slider-button next" 
          onClick={() => {
            nextSlide();
            handleInteractionStart();
          }}
          aria-label="Next review"
        >
          ❯
        </button>
      </div>
      <div className="slider-dots">
        {reviews.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => {
              setCurrentIndex(index);
              handleInteractionStart();
            }}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default Reviews;
