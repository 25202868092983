import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db, googleProvider } from '../firebase/config';
import { 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail 
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc, 
  collection, 
  getDocs, 
  deleteDoc, 
  limit, 
  query,
  runTransaction,
  serverTimestamp,
  where 
} from 'firebase/firestore';
import './auth.css';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get referral code from URL query parameters
    const params = new URLSearchParams(location.search);
    const referral = params.get('referral');
    if (referral) {
      setReferralCode(referral);
      setIsLogin(false); // Switch to signup mode when referral code is present
    }
  }, [location]);

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/invalid-credential':
        return 'Invalid email or password. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email. Please sign up first.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/email-already-in-use':
        return 'An account with this email already exists. Please log in instead.';
      case 'auth/weak-password':
        return 'Password is too weak. Please use at least 6 characters.';
      case 'auth/invalid-email':
        return 'Invalid email address. Please check and try again.';
      case 'auth/network-request-failed':
        return 'Network error. Please check your internet connection.';
      case 'auth/too-many-requests':
        return 'Too many failed attempts. Please try again later.';
      default:
        return 'An error occurred. Please try again.';
    }
  };

  const assignReferralCode = async (userId) => {
    try {
      const code = await runTransaction(db, async (transaction) => {
        // Get a random referral code
        const referralCodesRef = collection(db, 'referralCodes');
        const querySnapshot = await getDocs(query(referralCodesRef, limit(1)));
        
        if (querySnapshot.empty) {
          throw new Error('No available referral codes');
        }

        const referralDoc = querySnapshot.docs[0];
        const referralCode = referralDoc.data().code;

        // Delete the code from referralCodes
        transaction.delete(referralDoc.ref);

        // Add to ClaimedCodes
        const claimedCodeRef = doc(db, 'ClaimedCodes', referralDoc.id);
        transaction.set(claimedCodeRef, {
          code: referralCode,
          user: userId,
          claimed_time: serverTimestamp()
        });

        return referralCode;
      });

      return code;
    } catch (error) {
      console.error('Error assigning referral code:', error);
      return null;
    }
  };

  const validateReferralCode = async (code) => {
    if (!code) return true; // Referral code is optional
    
    try {
      // Query ClaimedCodes to check if the code exists
      const claimedCodesRef = collection(db, 'ClaimedCodes');
      const q = query(claimedCodesRef, where('code', '==', code));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setMessage('Invalid referral code. Please check and try again.');
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error validating referral code:', error);
      setMessage('Error validating referral code. Please try again.');
      return false;
    }
  };

  const validateEmail = (email) => {
    // Basic format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }

    // Check for common valid domains
    const validDomains = ['com', 'org', 'net', 'edu'];
    const domain = email.split('@')[1];
    if (!validDomains.some(d => domain.endsWith(`.${d}`))) {
      return 'Please use a valid email domain (e.g., .com, .org, .net, .edu)';
    }

    return null;
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    
    if (!isLogin) {
      // Validate email for signup
      const emailError = validateEmail(email);
      if (emailError) {
        setMessage(emailError);
        return;
      }

      if (password !== confirmPassword) {
        setMessage('Passwords do not match');
        return;
      }
    }

    try {
      if (isLogin) {
        // Login
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
          setMessage('Please verify your email before logging in. Check your inbox for the verification link.');
          return;
        }
        // Update user's emailVerified status in Firestore
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          emailVerified: true,
          email: userCredential.user.email,
          lastSignInTime: serverTimestamp()
        }, { merge: true });
        navigate('/dashboard');
      } else {
        // Signup
        if (!name.trim()) {
          setMessage('Please enter your name');
          return;
        }
        if (referralCode && !(await validateReferralCode(referralCode))) {
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        
        // Send verification email
        await sendEmailVerification(user);
        
        // Create user record in Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          const newReferralCode = await assignReferralCode(user.uid);
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            name: name.trim(),
            points: 0,
            coins: 0,
            referralCode: newReferralCode,
            referredBy: referralCode || null
          });
        }
        
        setMessage('Verification email sent! Please check your inbox and verify your email before logging in.');
        setIsLogin(true); // Switch to login mode
      }
    } catch (error) {
      setMessage(getErrorMessage(error));
    }
  };

  const handleGoogleAuth = async () => {
    try {
      // Validate referral code if provided
      if (referralCode && !(await validateReferralCode(referralCode))) {
        return;
      }

      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      // Check if user exists in Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        const newReferralCode = await assignReferralCode(user.uid);
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          name: user.displayName || '',  // Google accounts have displayName
          points: 0,
          coins: 0,
          referralCode: newReferralCode,
          referredBy: referralCode || null,
          emailVerified: true,
          lastSignInTime: serverTimestamp()
        });
      } else {
        // Update existing user's emailVerified status and sign-in time
        await setDoc(doc(db, 'users', user.uid), {
          emailVerified: true,
          lastSignInTime: serverTimestamp()
        }, { merge: true });
      }
      navigate('/dashboard');
    } catch (error) {
      setMessage(getErrorMessage(error));
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter your email address');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
      setIsResetPassword(false);
    } catch (error) {
      setMessage(getErrorMessage(error));
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setName('');
    setReferralCode('');
    setMessage('');
  };

  if (isResetPassword) {
    return (
      <div className="auth-container">
        <form onSubmit={handleResetPassword} className="auth-form">
          <h2>Reset Password</h2>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {message && <p className="message">{message}</p>}
          <button type="submit">Send Reset Link</button>
          <p>
            <a href="#" onClick={() => {setIsResetPassword(false); setMessage('');}}>
              Back to Login
            </a>
          </p>
        </form>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
        {!isLogin && referralCode && (
          <div className="referral-banner">
            <span className="referral-icon">🎁</span>
            <div className="referral-message">
              <p>You were invited by a friend!</p>
              <p className="referral-code">Referral Code: {referralCode}</p>
            </div>
          </div>
        )}
        {message && <div className="auth-message">{message}</div>}
        
        {!isLogin ? (
          <div className="signup-options">
            <div className="signup-method">
              <h3>Create account with email</h3>
              <form onSubmit={handleEmailAuth}>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button type="submit">Sign Up with Email</button>
              </form>
            </div>

            <div className="or-divider">
              <span>or</span>
            </div>

            <button onClick={handleGoogleAuth} className="google-btn">
              <img 
                src="https://www.google.com/favicon.ico" 
                alt="Google"
                className="google-icon"
              />
              <span>Sign up with Google</span>
            </button>
          </div>
        ) : (
          <>
            <form onSubmit={handleEmailAuth}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="forgot-password">
                <a href="#" onClick={(e) => {e.preventDefault(); setIsResetPassword(true); setMessage('');}}>
                  Forgot Password?
                </a>
              </div>
              <button type="submit">Login</button>
            </form>
            <button onClick={handleGoogleAuth} className="google-btn">
              <img 
                src="https://www.google.com/favicon.ico" 
                alt="Google"
                className="google-icon"
              />
              <span>Sign in with Google</span>
            </button>
          </>
        )}

        {!isLogin && (
          <div className="referral-section">
            <p className="referral-note">Have a referral code? Enter it below (works for both email and Google sign-up)</p>
            <input
              type="text"
              placeholder="Referral Code (Optional)"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
          </div>
        )}
        
        <p>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <span onClick={toggleMode} className="auth-link">
            {isLogin ? 'Sign Up' : 'Login'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Auth;
